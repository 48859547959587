<template>
  <div class="return">
    <div class="label d-flex">
      <section class="col-3">order number</section>
      <section class="col-2">commodity</section>
      <section class="col-2">time</section>
      <section class="col-3">status</section>
      <section class="col-2">operating</section>
    </div>
    <div class="list">
      <div class="item d-flex" v-for="item in cart">
        <div class="item-chidren col-3">{{ item.orders_no }}</div>
        <div class="item-chidren col-2">
          <img :src="item.thumb" alt="" />
        </div>
        <div class="item-chidren col-2">
          <p>{{ item.create_time }}</p>
        </div>
        <div class="item-chidren bt col-3">
          <section v-if="item.status == 0" style="color: #ff7f00">
            Wait for confirm
          </section>
          <section v-if="item.status == 1" style="color: #ff7f00">
            Returning
          </section>
          <section v-if="item.status == 2" style="color: #d34039">
            Return failed
          </section>
          <section v-if="item.status == 3" style="color: #ff7f00">
            In exchange
          </section>
          <section v-if="item.status == 4" style="color: #d34039">
            Exchange failed
          </section>
          <section v-if="item.status == 5" style="color: #71b247">
            Returned
          </section>
          <section v-if="item.status == 6" style="color: #71b247">
            Completed
          </section>
        </div>
        <div class="item-chidren bt col-2">
          <section>
            <span
              style="
                border: 1px solid;
                padding: 0.05rem 0.15rem;
                border-radius: 0.1rem;
              "
              @click="detail(item.id)"
            >
              View
            </span>
          </section>
          <section style="margin-top: 0.25rem">
            <span
              style="
                color: #d34039;
                border: 1px solid #d34039;
                padding: 0.05rem 0.15rem;
                border-radius: 0.1rem;
              "
              v-if="item.status == 2 || item.status == 4"
              @click="reapplyService(item.id)"
            >
              Re-apply
            </span>
          </section>
          <section style="margin-top: 0.25rem">
            <span
              style="
                color: #71b247;
                border: 1px solid #71b247;
                padding: 0.05rem 0.15rem;
                border-radius: 0.1rem;
              "
              @click="confirmReceiptReplacement(item.id)"
              v-if="item.status == 3"
            >
              Confirm receipt</span
            >
          </section>
        </div>
      </div>
    </div>

    <page
      ref="page"
      :ofPages="ofPages"
      :curPage="curPage"
      @change_page_num="get_page_num"
      @pageActive="pageActive"
    />
  </div>
</template>
<script>
import {
  getServicelist,
  reapplyService,
  confirmReceiptReplacement,
} from "network/order";
import Page from "./childcomponents/MainPager";
export default {
  data() {
    return {
      ofPages: 0,
      curPage: 1,
      curSize: 24,
      cart: [],
    };
  },

  components: { Page },
  created() {
    this.getServicelist(this.curPage, this.curSize);
  },
  methods: {
    reapplyService(id) {
      reapplyService(id).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.getServicelist(1, 24);
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },

    detail(id) {
      this.$router.push({
        path: "/member/returnDetail",
        query: {
          id,
        },
      });
    },
    confirmReceiptReplacement(id) {
      confirmReceiptReplacement(id).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.getServicelist(1, 24);
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },

    /*选择当前一页展示几项  从第一页开始 */
    get_page_num(data) {
      this.curPage = 1;
      this.curSize = data;
      this.$refs.page.page = 1;
      this.getServicelist(this.curPage, this.curSize);
    },
    /*选择页数*/
    pageActive(data) {
      this.curPage = data;
      this.getServicelist(this.curPage, this.curSize);
    },

    getServicelist(num, size) {
      getServicelist(num, size).then((res) => {
        if (res.data.code == 1) {
          this.cart = res.data.data.ordersService;
          this.ofPages = res.data.data.page_total;
        } else {
          this.cart = [];
        }
      });
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
section {
  margin: 0;
  padding: 0;
  text-align: center;
}
.label {
  background: #f2f2f2;
  margin: 0.25rem;
  padding: 0.15rem 0;
}
.label section {
  margin: 0;
  font-size: 0.35rem;
  color: #666666;
}
.bt section {
  cursor: pointer;
}
.item {
  color: #4c4c4c;
  font-size: 0.3rem;
  margin: 0 0.25rem;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
}
.item-chidren {
  margin: 0;
  padding: 0;
  text-align: center;
}

.item-chidren img {
  width: 80%;
}
</style>
